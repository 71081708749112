#logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  h1 {
    margin: 0;
  }
}

.logo-image {
  width: 12rem;
  // height: 3.5rem;
}

.logo-text {
  font-family: "Raleway", cursive;
  font-size: 1.5rem;
  font-weight: 800;
  font-style: italic;
}

@media only screen and (max-width: 64em) {
  .logo-text {
    font-family: "Raleway", cursive;
    font-size: 1.2rem;
    font-weight: 800;
    font-style: italic;
  }

  .logo-image {
    width: 20vh;
  }
}
