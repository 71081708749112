@import "../../Css-variables/var";

.about-us-container {
  padding: 1rem 0;
  text-align: justify;
  font-family: $f-openSans;
  // margin-top: 11vh;
  h2 {
    font-weight: 800;
  }
}
