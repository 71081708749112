.blog-content-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  // height: 100%;
  padding-top: 14vh;
  // overflow: scroll;
}

// .main-loader-container {
//   width: 100vw;
//   height: 100vh;
// }

@media only screen and (max-width: 67.813em) {
  .blog-content-container {
    flex-direction: column;
  }
}
