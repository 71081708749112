.search {
  width: 94%;
  border: none;
  outline: none;
  padding: 0.3rem 0;
  background: transparent;
}

.search-field {
  display: flex;
  width: 40%;
  border: 1px solid black;
  padding: 0 0.5rem;
  border-radius: 20px;
}

.search-button {
  background: transparent;
  border: none;
  outline: none;
  width: 6%;
}

@media only screen and (max-width: 40em) {
  .search-field {
    display: none;
  }
}

@media only screen and (min-width: 40.063em) and (max-width: 64em) {
}
