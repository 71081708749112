.topbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 1rem 1.5rem;
  // height: 11vh;
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
  padding: 0 1.5rem;
  max-width: 86.125em;
  margin: 0 auto;
  z-index: 2;
}

.mobile-search-button {
  display: none;
}

.admin-navitems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 15%;
}

.back-drop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.25);
  display: block;
}

.display-none {
  display: none;
}

.items {
  cursor: pointer;
}

@media only screen and (max-width: 40em) {
  #color-button {
    display: none;
  }

  .mobile-search-button {
    display: inline;
    background: transparent;
    border: none;
    outline: none;
    width: auto;
  }

  .topbar-container {
    width: 100%;
  }
}

@media only screen and (min-width: 40.063em) and (max-width: 64em) {
  #color-button {
    display: none;
  }
}
