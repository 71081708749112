.login-container {
  display: flex;
  flex-direction: column;
  width: 30%;
  border: 1px solid white;
  padding: 4rem;
  border-radius: 1rem;
  /* box-shadow: 10px 10px 8px -7px #9790906b; */
  background: linear-gradient(
    90deg,
    rgba(194, 192, 231, 1) 0%,
    rgba(104, 170, 195, 1) 35%,
    rgba(223, 54, 177, 1) 100%
  );
  backdrop-filter: blur(10px);
  color: white;
  background-repeat: no-repeat;
  //   margin: auto;
}

.login-input {
  margin-bottom: 1rem;
  border-radius: 1rem;
  border: 1px solid black;
  padding: 0 0.6rem;
}

.login-form {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15vh;
  width: 100%;
  margin: auto 0;
}

.login-form-submit {
  border-radius: 1rem;
  margin-top: 1rem;
  background-color: #242f9b;
  border: none;
  color: white;
  padding: 0.2rem 0;
}

@media only screen and (max-width: 68.75em) {
  .login-container {
    width: 50%;
  }
}

@media only screen and (max-width: 39em) {
  .login-container {
    width: 80%;
  }
}

@media only screen and (max-width: 22.688em) {
  .login-container {
    width: 100%;
  }
}
