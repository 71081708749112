.editor-container {
  //   position: relative;
  height: 14rem;
  margin-bottom: 3rem;
  //   overflow-y: auto;
}

// .ql-toolbar {
//   position: fixed;
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   z-index: 1;
//   background-color: #f0f0f0;
// }
