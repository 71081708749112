@import "../../Css-variables/mixin";

.post-thumbnail-container {
  // min-width: 18.25rem;
  height: 23.75rem;
  // height: 100%;
  //If number of blogs are more than four than deactivate width and height
  border-radius: 20px;
  overflow: hidden;
  //   border: 1px solid rgb(114, 110, 110);
  // box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.25);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  font-family: "Open Sans", sans-serif;
  // margin-inline: 0.4rem;

  // align-self: stretch;
}

.post-image-container {
  width: 100%;
  // overflow: hidden;
  height: 70%;
  img {
    object-fit: fill;
    width: 100%;
    height: 100%;
  }
}

.post-text-container {
  padding: 1rem;
  border: 1px solid #dfd4d4;
  // height: 100%;
  height: 30%;
  h3 {
    margin: 0.7rem 0;
    font-size: 1rem;
    font-weight: 700;
    color: black;
    // text-align: justify;
  }
}

.blog-category {
  color: #082ef5;
  font-weight: 700;
  font-size: 0.9rem;
  margin: 0;
}

.post-owner {
  color: rgb(255, 255, 255);
  font-size: 0.8rem;
  background-color: rgb(34, 33, 33);
  padding: 0.1rem 0.5rem;
  border-radius: 10px;
  margin: 0;
  display: inline-block;
}

.post-category-container {
  // display: flex;
  // justify-content: space-between;
}

.post-desc {
  color: rgb(63, 63, 63);
  font-size: 0.8rem;
  margin: 0.2rem 0;
}

.post-view-container {
  display: flex;
  justify-content: space-between;
  margin-top: 0.4rem;
  color: black;
  p {
    font-size: 0.8rem;
    margin: 0;
  }
}

.post-link {
  text-decoration: none;
}

.custom-button {
  @include margin-right(0.5rem);
  padding: 0.1rem 1rem;
  font-size: 0.9rem;
}

@media only screen and (max-width: 30em) {
  .post-thumbnail-container {
    align-self: center;
    width: 100%;
  }
  // .post-image-container {
  //   height: 60%;
  // }
}
