#posts-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  grid-auto-rows: min-content;
  gap: 0.5rem;
  margin-bottom: 2rem;
  width: 100%;
  padding-top: 1rem;
}
// If number of blogs are more then four then activate the above class

// #posts-container {
//   display: flex;
//   align-items: stretch;
//   flex-flow: row wrap;
//   gap: 0.8rem;
//   // justify-content: space-between;
//   margin-bottom: 1rem;
// }

.align-loading {
  display: flex;
  width: 100%;
  height: 20vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0;
  grid-column: 1 / -1;
}
