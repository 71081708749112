@import "../../Css-variables/var";

.editing-form-container {
  display: flex;
  flex-direction: column;
  font-family: $f-openSans;
  overflow-y: auto;
  label {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
}

.blog-input-container {
  margin-block: 0.8rem;
  display: flex;
  flex-direction: column;
  input[type="text"] {
    border-radius: 5px;
    border: 1px solid black;
    padding: 0.2rem 1rem;
    word-wrap: break-word;
    font-size: 0.9rem;
  }
  input[type="file"] {
    border: 1px solid black;
    border-radius: 5px;
    background: transparent;
    font-size: 0.9rem;
  }
}

.note-warning {
  color: red;
}

.success-message {
  font-size: 0.7rem;
  color: green;
  margin: 0;
}

.error-message {
  font-size: 0.7rem;
  color: red;
  margin: 0;
}

.blog-btn-group {
  position: relative;
  i button:first-child {
    margin-right: 0.5rem;
  }
}
