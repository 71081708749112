.navbar {
  width: 100%;
  background: white;
  padding-inline: 1.5rem;
  align-items: flex-end;
  max-width: 86.125em;
  margin: 0 auto;
}

.navbar-background {
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: 0.5s ease-in-out;
}

.navbar-nav {
  text-align: center;
  justify-content: end;
}

.navbar-collapse {
  justify-content: flex-end;
}

.navbar-toggler {
  border: none;
  padding: 0;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.nav-item {
  margin-inline: 1rem;
  padding-inline: 0.8rem;
  padding-block: 0.2rem;
  // color: rgb(95, 91, 91);
  // color: black;
  cursor: pointer;
  border-radius: 12px;
  transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
  &:last-child {
    margin-right: 0;
  }
}

.active {
  color: #ffffff;
  // font-weight: bold;
  // border-bottom: 4px solid rgb(8, 8, 139);
  background-color: rgb(8, 8, 139);
  transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
}

button:focus:not(:focus-visible) {
  outline: none;
}

@media only screen and (max-width: 62em) {
  .navbar-collapse {
    justify-content: center;
  }

  .navbar {
    padding-inline: 1rem;
  }

  .nav-item {
    &:first-child {
      margin-top: 2rem;
    }
    &:last-child {
      margin-right: 1rem;
    }
  }
}
