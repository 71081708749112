#page-not-found {
  width: 100%;
  height: 95vh;
  padding-top: 12vh;
  color: black;
  //   font-size: 3rem;
  //   border: 1px solid black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    font-size: 6rem;
  }
  p {
    font-size: 3rem;
  }
}
