@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

.App {
  padding: 0 1.5rem;
  // height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  max-width: 86.125em;
  margin: 0 auto;
}

.main-container {
  display: flex;
  width: 100%;
}

@media only screen and (max-width: 30em) {
  .App {
    padding: 0 0.8rem;
  }
}
