body {
  margin: 0;
  // padding: 0 0.8rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
