.main-body {
  width: 100%;
  height: 100%;
  padding-top: 12vh;
  // padding-left: 14vh;
  //   margin-bottom: 1rem;
  background: transparent;
}

.margin-top {
  margin-top: 2rem;
}

@media only screen and (max-width: 64em) {
  .main-body {
    padding-left: 0;
    padding-top: 8vh;
  }
}
